<template>
    <v-form class="pl-8 mt-12 mb-12" ref="reasonForm" v-on:submit.prevent="onSubmit">
        <v-radio-group :rules="[rules.required]" v-model="editedRegistrationProcess.reason">
            <v-radio label="AJA" value="aja" @click="openAjaInformations"></v-radio>
            <v-radio-group :rules="isAja ? [rules.required] : []" class="mt-0 ml-12" v-model="editedRegistrationProcess.aja_role">
                <v-radio :disabled="!isAja" key="aja_young" label="Je suis un jeune MAJEUR (18 - 26 ans)" value="aja_young"></v-radio>
                <v-radio :disabled="!isAja" key="aja_assistant" label="Je suis parent ou repésentant légal d’un jeune MINEUR" value="aja_assistant"></v-radio>
            </v-radio-group>

            <v-radio label="Je suis envoyé.e par la Ligue contre le cancer" value="ligue"></v-radio>

            <v-radio label="Ma couverture santé prévoyance est" value="healthinsurance"></v-radio>
            <v-radio-group :rules="isHealthinsurance ? [rules.required] : []" class="mt-0 ml-12"
                v-model="editedRegistrationProcess.partner.id">
                <v-radio :disabled="!isHealthinsurance" v-for="partner in healthInsurancePartners" :key="partner.id"
                    :label="partner.name" :value="partner.id"></v-radio>
            </v-radio-group>

            <v-radio label="Je suis retraité.e et ma complémentaire retraite est" value="retreat"></v-radio>
            <v-radio-group :rules="isRetreat ? [rules.required] : []" class="mt-0 ml-12"
                v-model="editedRegistrationProcess.partner.id">
                <v-radio :disabled="editedRegistrationProcess.reason !== 'retreat'" v-for="partner in retreatPartners"
                    :key="partner.id" :label="partner.name" :value="partner.id"></v-radio>
            </v-radio-group>

            <v-radio label="Mon employeur est" value="employer"></v-radio>
            <v-radio-group class="mt-0 ml-12" :rules="isEmployer ? [rules.required] : []"
                v-model="editedRegistrationProcess.partner.id">
                <v-radio :disabled="!isEmployer" v-for="partner in employerPartners" :key="partner.id" :label="partner.name"
                    :value="partner.id"></v-radio>
            </v-radio-group>
            <v-radio label="Je suis envoyé.e par Patients en réseau"
                value="patientsreseau"></v-radio>
            <v-radio label="Autres" value="other"></v-radio>
        </v-radio-group>
        <v-btn color="primary" @click="submitForm"> Valider </v-btn>
        <v-btn text @click="cancel"> Annuler </v-btn>

        <v-dialog persistent v-model="ajaInformationsDialog" max-width="1000">
            <v-card style="background-color: #1E3945;" class="text-center pt-6">
                <v-card-title>
                    <h2>Accueil sur la Plateforme onCOGIT’aja</h2>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="ajaInformationsDialog = false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="text-left">
                    Bonjour,
                    Vous allez vous inscrire au parcours de remédiation cognitive onCOGIT’aja réservé aux jeunes de 15 à 26 ans
                    ayant été soignés pour un cancer (et leurs aidants).<br/><br/>
                    Ce parcours suit le cheminement ci-dessous:
                    <ul>
                        <li>Accord du jeune majeur (ou de ses parents pour les mineurs) pour participer à
                            une prise en charge neuropsychologique et inscription en ligne sur la plateforme onCOGIT’aja.</li>
                        <li>Dans le cadre du projet, les AJA et leurs accompagnants se voient offrir leur adhésion à onCOGITE (de 20€/an)</li>
                        <li>Remplissage des questionnaires de renseignement et d’auto-évaluation par le jeune</li>
                        <li>Remplissage des questionnaires de renseignement et d’hétéro-évaluation par les parents ou un aidant.</li>
                        <li>Possibilité de participation à un (ou 2) webinaire d’informations pour les Parents-Aidants.</li>
                        <li>Participation à l’atelier d’éducation thérapeutique et d’information en ligne «infoCOG’aja»
                            avant de débuter les ateliers de remédiation cognitive (1h30) </li>
                        <li>Participation à 15 (ou+) ateliers en ligne de rééducation cognitive d’une heure,
                            encadrés par une neuropsychologue spécialisée dans la prise en charge de l’oncobrain des jeunes.</li>
                        <li>Remplissage des questionnaires de satisfaction et d’auto-évaluation par le jeune</li>
                        <li>Remplissage des questionnaires de satisfaction et d’hétéro-évaluation par les parents ou l’aidant.</li>
                    </ul>
                    <br/>
                    Cet accompagnement est basé sur la souplesse afin de faciliter l’assiduité du jeune au cours de ses 15 séances (ou +) de remédiation
                    (une chaque semaine).<br/> <br/>
                    Il n’y a pas d’engagement financier demandé aux participants ni à leur famille, en revanche,
                    c’est l’investissement personnel en temps qui fera le succès de la rééducation.<br/><br/>
                    Les questionnaires sont anonymisés et les données collectives extraites de cette expérimentation
                    seront exclusivement réservées à l’évaluation et l’amélioration de l’accompagnement qui sera proposé à
                    l’issue de cette phase. Les participants auront connaissance de ce ces évaluations.<br/><br/>
                    Merci de choisir entre ces 2 situations possibles:
                    <v-radio-group id="aja-radio-group" :rules="isAja ? [rules.required] : []" class="mt-0 ml-12" v-model="editedRegistrationProcess.aja_role">
                        <v-radio :color="isThemeAja ? '#4E983C' : 'primary'" @click="ajaInformationsDialog = false" :disabled="!isAja" key="aja_young" label="Je suis AJA MAJEUR et j’accepte de participer à onCOGIT’aja" value="aja_young"></v-radio>
                        <v-radio :color="isThemeAja ? '#4E983C' : 'primary'" @click="ajaInformationsDialog = false" :disabled="!isAja" key="aja_assistant" label="Je suis représentant légal d’un AJA Mineur et j’accepte que mon enfant participe à onCOGIT’aja" value="aja_assistant"></v-radio>
                    </v-radio-group>
                    <span>Si vous êtes parent/aidant d’un AJA Majeur vous ne pourrez vous inscrire qu'une fois que votre jeune aura créé son compte</span>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props: {
        ajaBackground: { type: Boolean, required: false }
    },
    data() {
        return {
            allPartners: [],
            editedRegistrationProcess: {
                partner: {}
            },
            rules: { required: value => !!value || 'Champ requis' },
            ajaInformationsDialog: false,
        }
    },
    mounted() {
        this.getPartners()
    },
    watch: {
        'editedRegistrationProcess.reason'(newReason, oldReason) {
            if (newReason !== oldReason) {
                if (newReason === 'patientsreseau') {
                    // auto select partner id for AdhCode step
                    this.editedRegistrationProcess.partner.id = this.patientsreseauPartners[0].id;
                } else {
                    // Reset partner ID when registration reason changes
                    this.editedRegistrationProcess.partner.id = null;
                }

                if(newReason === 'aja') {
                    this.setThemeAja(true)
                } else {
                    this.setThemeAja(false)
                }
            }
        },
    },
    computed: {
        ...mapGetters(['isThemeAja']),
        isLigue() {
            return this.editedRegistrationProcess.reason === "ligue"
        },
        isHealthinsurance() {
            return this.editedRegistrationProcess.reason === "healthinsurance"
        },
        isEmployer() {
            return this.editedRegistrationProcess.reason === "employer"
        },
        isRetreat() {
            return this.editedRegistrationProcess.reason === "retreat"
        },
        isAja() {
            return this.editedRegistrationProcess.reason === "aja"
        },
        liguePartners() {
            return this.allPartners.filter(p => p.support_type === 'ligue')
        },
        healthInsurancePartners() {
            return this.allPartners.filter(p => p.support_type === 'healthinsurance')
        },
        employerPartners() {
            return this.allPartners.filter(p => p.support_type === 'employer')
        },
        retreatPartners() {
            return this.allPartners.filter(p => p.support_type === 'retreat')
        },
        patientsreseauPartners() {
            return this.allPartners.filter(p => p.support_type === 'patientsreseau')
        }
    },
    methods: {
        ...mapActions(['setThemeAja']),
        getPartners() {
            this.$store.dispatch('partnersRequest', {})
                .then((result) => this.allPartners = result.data)
                .catch(() => this.$store.dispatch('setErrors', 'Error while fetching partners'))
        },
        submitForm() {
            const isValid = this.$refs.reasonForm.validate();
            if (isValid) {
                this.$emit("submitReasonForm", this.editedRegistrationProcess);
            }
        },
        cancel() {
            this.$emit('cancelUpdateReason')
            this.editedRegistrationProcess = {
                partner: {}
            }
        },
        openAjaInformations() {
            this.ajaInformationsDialog = true
        }
    }
}
</script>
